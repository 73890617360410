.projects-container{
    width:100%;
    margin-top:0px;
    height:auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

}

    .projects-project-link-container{
        position:relative;
        flex: 1;
        /* margin: auto; */
        margin: 0 20px 0 auto;
        width:400px;
        height:330px;
        padding-top:50px;
        /* border:1px solid green; */
        
    }


    @media only screen and (max-width: 1100px) {
        .projects-container{
            
            width:100%;
            max-width: 800px;
            margin:0px auto;
            height:auto;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            /* border:1px solid red; */
        
        }
        
            .projects-project-link-container{
                position:relative;
                flex: 1;
                /* margin: auto; */
                margin: 0 0px 0 0;
                width:50vw;
                height:50vw;
                max-width:50vw;
                max-height:50vw;
                padding-top:1vw;
                /* border:1px solid green; */
                
            }
    }