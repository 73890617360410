.profile-box-container{
    /* border:1px solid blue; */
    width:100%;
    height:170px;
    margin-top:50px;
    z-index:10;}

    .profile-box-name-container{
        /* border: 1px solid red;} */
        width: 750px;
        height:90px;
        margin:auto;
        border-bottom: 1px solid #ddd;}
        
        .profile-box-name{
            font-size:70px;
            width:540px;
            float:left;
            /* border:1px solid green; */
            font-family: 'Rajdhani', sans-serif;}

    .profile-box-school-container{
        /* border:1px solid blue; */
        width:750px;
        height:50px;
        margin:15px auto 0 auto;
        padding:auto;}

        .profile-box-school{
            margin:auto;
            font-size:20px;
            font-weight: 200}

        .linkedin-link-container{
            /* border:1px solid blue; */
            background:rgb(37, 124, 195);
            /* width:50px; */
            /* height:50px; */
            padding:3px 6px;
            margin-top:20px;
            border-radius: 5px;
            float:left;
        }



        .home-link-container{
            width:980px;
            height:100px;
            overflow: hidden;
            /* border:1px solid blue; */
            margin: auto;
            position: relative;
            top:-70px;
            z-index: 20;

        }
        .home-link{
            width:40px;
            opacity:.2;
            margin:auto;
            transition:.3s ease all;
            z-index: 20;
        }
        .home-link:hover{
            opacity: .5;
            transition:.3s ease all;
            
        }
        .is-home{
            opacity: 0;
            transition: 1s ease all;
        }
        .is-home:hover{
            opacity: 0;
            cursor: default;
        }


        @media only screen and (max-width: 1100px) {
            .profile-box-container{
                /* border:1px solid blue; */
                width:100%;
                height:17vw;
                margin-top:50px;
                z-index:10;}
            
                .profile-box-name-container{
                    /* border: 1px solid red;} */
                    width: 55vw;
                    height:9vw;
                    margin:auto;
                    border-bottom: 1px solid #ddd;}
                    
                    .profile-box-name{
                        font-size:7vw;
                        width:34vw;
                        float:left;
                        /* border:1px solid green; */
                        font-family: 'Rajdhani', sans-serif;}
            
                .profile-box-school-container{
                    /* border:1px solid blue; */
                    width:55vw;
                    height:5vw;
                    margin:15px auto 0 auto;
                    padding:auto;}
            
                    .profile-box-school{
                        margin:auto;
                        font-size:2vw;
                        font-weight: 200}
            
                    .linkedin-link-container{
                        /* border:1px solid blue; */
                        background:rgb(37, 124, 195);
                        /* width:50px; */
                        /* height:50px; */
                        padding:0px 6px;
                        margin-top:2vw;
                        border-radius: 5px;
                        float:left;
                    }
            
            
            
                    .home-link-container{
                        width:80vw;
                        height:200px;
                        overflow: hidden;
                        /* border:1px solid blue; */
                        margin: auto;
                        position: relative;
                        top:-7vw;
                        z-index: 20;
            
                    }
                    .home-link{
                        width:5vw;
                        opacity:.2;
                        margin:auto;
                        transition:.3s ease all;
                        z-index: 20;
                    }
                    .home-link:hover{
                        opacity: .5;
                        transition:.3s ease all;
                        
                    }
                    .is-home{
                        opacity: 0;
                        transition: 1s ease all;
                    }
                    .is-home:hover{
                        opacity: 0;
                        cursor: default;
                    }
            
        }